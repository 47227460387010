import React from "react";
import { cn } from "@/lib/utils";

export const AppH1: React.FC<{
  readonly className?: string;
  readonly children: React.ReactNode;
}> = ({ className, children }) => {
  return (
    <h1
      className={cn(
        "scroll-m-20 pb-2 pl-8 pr-8 pt-2 text-2xl font-bold tracking-tight text-foreground lg:text-4xl",
        className,
      )}
    >
      {children}
    </h1>
  );
};

export const AppH2: React.FC<{
  readonly className?: string;
  readonly children: React.ReactNode;
}> = ({ className, children }) => {
  return (
    <h1
      className={cn(
        "text-1xl mt-10 scroll-m-20 pb-2 pl-8 font-semibold tracking-tight text-foreground transition-colors first:mt-0",
        className,
      )}
    >
      {children}
    </h1>
  );
};

export const AppP: React.FC<{
  readonly className?: string;
  readonly children: React.ReactNode;
}> = ({ className, children }) => {
  return <p className={cn("px-8 text-foreground", className)}>{children}</p>;
};
